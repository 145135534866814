import { Container, Skeleton, Stack } from "@mui/material";
import "./NavBar.scss";

import logo from "assets/logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import AuthService from "services/auth.service";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import BasicMenu from "components/atoms/BasicMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { useCallback, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import LocalStorage from "utils/localstorage.util";
import { currencyList } from ".";
import { xorDecrypt, xorEncrypt } from "utils/encryptions.util";
import { config } from 'config/config'

const NavBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");
  const user = useAppSelector((state) => state.auth.user);
  // const origin = useAppSelector((state) => state.auth.orgin);
  const { pathname } = useLocation();

  const [state, setState] = useState(false);
  // const [currencySelected, setCurrencySelected] = useState("SAR");

  const toggleDrawer = () => {
    setState(!state);
  };

  const changeLanguage = useCallback((lng: any) => {

    i18n.changeLanguage(lng);

  }, [i18n])

  const arabicLang = useCallback(() => {
    localStorage.setItem("lang", 'ar')

    changeLanguage('ar');

    document.body.style.direction = "rtl";
    document.body.style.fontFamily = "arabic";
  }, [changeLanguage])
  const englishLang = useCallback(
    () => {
      localStorage.setItem("lang", 'en')

      changeLanguage('en');
      document.body.style.direction = "ltr";
      document.body.style.fontFamily = "inter";

    }, [changeLanguage]
  )

  useEffect(() => {

    if (i18n.language === 'ar') {
      arabicLang()
    } else {
      englishLang()
    }

  }, [arabicLang, englishLang, i18n.language])

  useEffect(() => {
    AuthService.getOriginLocation()
  }, [])

  // useEffect(() => {
  //   if (LocalStorage.getItem("cs_id")) {
  //     const encryptedData: string | null = LocalStorage.getItem('c_id');
  //     if (encryptedData) {
  //       const decryptedCurrency: string = xorDecrypt(encryptedData, config.ENCRYPTION_KEY);
  //       setCurrencySelected(decryptedCurrency);
  //     } else setCurrencySelected("SAR");
  //     return
  //   }
  //   if (!origin) {
  //     const encryptedCurrency: string = xorEncrypt("SAR", config.ENCRYPTION_KEY)
  //     LocalStorage.setItem("c_id", encryptedCurrency);
  //     setCurrencySelected("SAR");
  //     return
  //   }
  //   switch (origin) {
  //     case "SA":
  //       const encryptedCurrencySAR: string = xorEncrypt("SAR", config.ENCRYPTION_KEY)
  //       LocalStorage.setItem("c_id", encryptedCurrencySAR);
  //       setCurrencySelected("SAR");
  //       break;
  //     case "AE":
  //       const encryptedCurrencyAED: string = xorEncrypt("AED", config.ENCRYPTION_KEY)
  //       LocalStorage.setItem("c_id", encryptedCurrencyAED);
  //       setCurrencySelected("AED");
  //       break;
  //     case "EG":
  //       const encryptedCurrencyEGP: string = xorEncrypt("EGP", config.ENCRYPTION_KEY)
  //       LocalStorage.setItem("c_id", encryptedCurrencyEGP);
  //       setCurrencySelected("EGP");
  //       break;
  //     default:
  //       const encryptedCurrency: string = xorEncrypt("SAR", config.ENCRYPTION_KEY)
  //       LocalStorage.setItem("c_id", encryptedCurrency);
  //       setCurrencySelected("SAR");
  //       break;
  //   }

  // }, [origin])

  // const handleCurrency = (currency: string) => {
  //   const encryptedCurrency: string = xorEncrypt(currency, config.ENCRYPTION_KEY)
  //   LocalStorage.setItem("c_id", encryptedCurrency);
  //   LocalStorage.setItem("cs_id", true)
  //   setCurrencySelected(currency);
  //   window.location.reload();
  // }

  const links = [
    {
      path:
        i18n.language === "en" ?
          "https://iyelo.com/en" :
          "https://iyelo.com/ar"

      , text: t("backToYelo")
    },
    { path: "/", text: t("services") },
    { path: "/business", text: t("business") },
    // { path: "/unforgettable-moments", text: t("unforgettable_moments") },
    
    { path: "/about#service", text: t("about") },
  ];
  if (pathname === "/payment-form") return <></>;
  return (
    <div className="navbar__container">
      <Container maxWidth="xl">
        <div className="navbar">
          <Stack
            className="cursor"
            onClick={() => navigate("/")}
            direction="row"
          >
            <img src={logo} alt="" />
          </Stack>
          <div className="routes">
            {links.map(({ path, text }, i) => (
              <NavLink key={i} to={path} className="links">
                {text}
              </NavLink>
            ))}
            <BasicMenu
              list={[
                {
                  text: "EN",
                  onClick: englishLang,
                },
                {
                  text: "عربي",
                  onClick: arabicLang,
                },
              ]}
              avatar={false}
              color="#ffffff"
            >
              <Stack direction="row" alignItems="center" columnGap={1}>
                <span>{i18n.language === "en" ? "EN" : "عربي"}</span>
                <FaAngleDown />
              </Stack>
            </BasicMenu>
            {/* <BasicMenu
              list={
                currencyList.map((item: any) => ({
                  text: item,
                  onClick: () => handleCurrency(item)
                }))}
              avatar={false}
              color="#ffffff"
            >
              <Stack direction="row" alignItems="center" columnGap={1}>
                <span>{currencySelected || "SAR"}</span>
                <FaAngleDown />
              </Stack>
            </BasicMenu> */}
          </div>
          <div className="hide__login">
            {user ? (
              <Stack direction="row" spacing={2}>
                <BasicMenu
                  list={[
                    {
                      text: t("profile"),
                      onClick: () => navigate("/profile"),
                    },
                    {
                      text: t("bookings"),
                      onClick: () => navigate("/bookings"),
                    },
                    {
                      text: "Wallet",
                      onClick: () => navigate("/wallet"),
                    },
                    {
                      text: t("logout"),
                      onClick: () => AuthService.logout(),
                    },
                  ]}
                  avatar={true}
                >
                  {user.first_name.charAt() + user.last_name.charAt()}
                </BasicMenu>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => navigate("/login")}
                  variant="text"
                  color="primary"
                >
                  {t("login")}
                </Button>
                <Button
                  onClick={() => navigate("/signup")}
                  variant="contained"
                  color="primary"
                >
                  {t("signup")}
                </Button>
              </Stack>
            )}
          </div>
        </div>
      </Container>

      <MenuIcon onClick={toggleDrawer}

        className={i18n.language === "ar" ? "burger__icons ar_burger" : "burger__icons"}
      />
      <SideBar state={state} toggleDrawer={toggleDrawer} englishLang={englishLang} arabicLang={arabicLang} />
    </div>
  );
};

export default NavBar;
