import http from "./http.service";
import { reset } from "redux-form";
import SocketService from "./socket.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import ToasterService from "../utils/toaster.util";
import { NavigateFunction } from "react-router-dom";
import { authActions } from "./../redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";

const AuthService = {
	signup: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));
		http.acceptLanguage()
		const [success, error]: any = await Promisable.asPromise(
			http.post(`/signup`, data)
		);

		if (success) {
			const { user, token } = success.data.data;

			localStorage.setItem("token", `Bearer ${token}`);
			dispatch?.(authActions.setUser(user));

			ToasterService.showSuccess("Account created successfully");
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	login: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));
		http.acceptLanguage()
		const [success, error]: any = await Promisable.asPromise(
			http.post(`/login`, data)
		);

		if (success) {
			const { user, token } = success.data.data;

			localStorage.setItem("token", `Bearer ${token}`);
			dispatch?.(authActions.setUser(user));


			SocketService.connect(user);
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	splash: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		http.setJWT();
		http.acceptLanguage()

		const [success, error]: any = await Promisable.asPromise(
			http.get("/splash")
		);

		if (success) {
			const { user } = success.data.data;
			dispatch?.(authActions.setUser(user));
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	forgotCredentials: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));
		http.acceptLanguage()

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/forgotCreds`, data)
		);

		if (success) {

			const info = success.data.data;
			dispatch?.(authActions.setOtpInfo(info))
			navigate?.("/select-otp-option");
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},
	getOtp: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));
		http.acceptLanguage()
		const [success, error]: any = await Promisable.asPromise(
			http.post(`/getOtp`, data)
		);

		if (success) {
			navigate?.("/password-otp");
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	verifyOTP: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));
		http.acceptLanguage()
		const [success, error]: any = await Promisable.asPromise(
			http.post(`/verifyOTP`, data)
		);

		if (success) {
			navigate?.("/reset-password")
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	resetPassword: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));
		http.acceptLanguage()
		const [success, error]: any = await Promisable.asPromise(
			http.post(`/resetpassword`, data)
		);

		if (success) {
			localStorage.removeItem("email");

			navigate?.("/login");
			setTimeout(() => {
				dispatch?.(reset("LoginForm"));

			}, 1000);
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	updateProfile: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		http.setJWT();
		http.acceptLanguage()
		const [success, error]: any = await Promisable.asPromise(
			http.post("/updateprofile", data)
		);

		if (success) {
			const { user } = success.data.data;
			dispatch?.(authActions.setUser(user));
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	uploadProfile: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(modalActions.setLoading(true));

		http.setJWT();
		http.setMultiPart();
		http.acceptLanguage()

		const [success, error]: any = await Promisable.asPromise(
			http.post("/updateImage", data)
		);

		if (success) {
			const { user } = success.data.data;
			dispatch?.(authActions.setUser(user));
			dispatch?.(modalActions.closeModal());
		}

		dispatch?.(modalActions.setLoading(false));
		return [success, error];
	},

	updateBillingAddress: async (data: any) => {
		const dispatch = getAppDispatch();
		http.setJWT();
		http.acceptLanguage()

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/updatebillingaddress`, data)
		);

		if (success) {
			const { user } = success.data.data;
			dispatch?.(authActions.setUser(user));
		}

		return [success, error];
	},

	deleteAccount: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(modalActions.setLoading(true));

		http.setJWT();
		http.acceptLanguage()

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/delete`, data)
		);

		if (success) AuthService.logout();

		dispatch?.(modalActions.setLoading(false));
		return [success, error];
	},

	logout: () => {
		const dispatch = getAppDispatch();

		SocketService.disconnect();

		localStorage.removeItem("token");

		dispatch?.(authActions.logout());
		dispatch?.(authActions.setUser(null));
	},

	getOriginLocation: async () => {
		const dispatch = getAppDispatch();
		const [success, error]: any = await Promisable.asPromise(
			http.get(`/geolocation`)
		);

		if (success) {
			const { data } = success.data;
			dispatch?.(authActions.setOrgin(data));
		}

		return [success, error]
	}
};

export default AuthService;
