import "./ClassPage.scss";
// Assets
import classImage from "assets/classes/van-banner.png";
import modelMiddle from "assets/classes/van-middle.png";
import key1 from "assets/classes/van-feature-1.webp";
import key2 from "assets/classes/van-feature-2.png";
import key3 from "assets/classes/van-feature-3.png";
import key4 from "assets/classes/van-feature-4.webp";
import liked1 from "assets/liked1.webp";
import btnNext from "assets/btnNext.svg";
import liked2 from "assets/liked2.webp";
import liked3 from "assets/liked3.webp";
// MUI
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



const VanClass = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation")
  const { i18n } = useTranslation();


  const arr = [
    {
      img: liked1,
      name: t("firstClassCar"),
      link: "/first-class",
    },
    {
      img: liked2,
      name: t("luxurySuv"),

      link: "/luxury-class",
    },
    {
      img: liked3,
      name: t("businessCar"),

      link: "/business-class",
    },
  ];

  return (
    <div className="class__container">
      <div className="class__header">
        <img src={classImage} alt="" />
      </div>
      <div className="class__name">
        <div className={i18n.language === "en" ? "first" : "first changeArabic"}


        >{t("businessVanClass")}</div>
        <div
          className={i18n.language === "en" ? "second" : "second changeArabic"}


        >
          {t("todaysLife")}
        </div>
        <div

          className={i18n.language === "en" ? "third" : "third changeArabic"}



        >
          <b>{t("harmonOkinyo")}</b>
        </div>
      </div>
      <div className="models">
        <div className="model">
          <img src={modelMiddle} alt="" />
          <div className="mode__heading">          {t("vanClass")}
          </div>
          <div className="modal__para">
            {t("forThePictures")}
          </div>
        </div>
      </div>
      <Container maxWidth="lg">
        <div className="keyFeature">
          <div className="header">{t("keyFeatures")}</div>
          <div className="keyBottom">
            <div className="left">
              <img src={key1} alt="" className="upper" />
              <img src={key2} alt="" className="bottom" />
            </div>
            <div className="right">
              <img src={key3} alt="" className="upper" />
              <img src={key4} alt="" className="bottom" />
            </div>
          </div>
        </div>
        <div className="liked__cars">
          <div className="heading">
            {t("mightLike")}
          </div>
          <Grid container className="cars">
            {arr.map((data: any, i: number) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
                  <div className="car1">
                    <img src={data.img} alt="" className="car__image" />
                    <div className="card__heading">{data.name}</div>
                    <div
                      className="more__btn cursor"
                      onClick={() => navigate(data.link)}
                    >
                      <div className="btn_text">{t("moreInfo")}</div>
                      <img
                        className={i18n.language === "ar" ? "rotate" : ""}

                        src={btnNext} alt="" />
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default VanClass;
