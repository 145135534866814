import i18n from 'i18next';
import LocalStorage from './localstorage.util';
import { xorDecrypt } from './encryptions.util';
import { config } from 'config/config'
export const getLanguage = () => {
  return i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en';
};
export const getCurrency = () => {
  const encryptedData: string | null = LocalStorage.getItem('c_id');
  if (encryptedData) {
    const decryptedCurrency: string = xorDecrypt(encryptedData, config.ENCRYPTION_KEY);
    return decryptedCurrency
  } else return "SAR"
};