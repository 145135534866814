import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import EditMapAddress from "../EditMapAddress";

const AddCoupon = lazy(() => import("./AddCoupon"));
const AddPayment = lazy(() => import("./AddPayment"));
const AddStripePayment = lazy(() => import("./AddStripePayment"));
const DeleteUser = lazy(() => import("./DeleteUser"));
const PaymentCards = lazy(() => import("./PaymentCards"));
const ChildrenSeats = lazy(() => import("./ChildrenSeats"));
const UpdateBooking = lazy(() => import("./UpdateBooking"));
const UpdateProfile = lazy(() => import("../UpdateProfile"));
const BillingAddress = lazy(() => import("../BillingAddress"));
const BookingDetails = lazy(() => import("./BookingDetails"));
const UploadDocument = lazy(() => import("./UploadDocument"));
const ReportAProblem = lazy(() => import("./ReportAProblem"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));

export { default } from "./AppModal";

export type ModalMapper = {
	[key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
	CANCEL_BOOKING: "",
	CONFIRM_CANCEL_BOOKING: "",

	DELETE_USER: <DeleteUser />,
	ADD_PAYMENT: <AddPayment />,
	APPLY_COUPON: <AddCoupon />,
	PAYMENT_CARDS: <PaymentCards />,
	CHILDREN_SEATS: <ChildrenSeats />,
	UPDATE_BOOKING: <UpdateBooking />,
	UPDATE_PROFILE: <UpdateProfile />,
	BILLING_ADDRESS: <BillingAddress />,
	BOOKING_DETAILS: <BookingDetails />,
	UPLOAD_DOCUMENT: <UploadDocument />,
	REPORT_A_PROBLEM: <ReportAProblem />,
	CONFIRMATION_FORM: <ConfirmationForm />,
	CONFIRMATION_MODAL: <ConfirmationModal />,
	ADD_STRIPE_PAYMENT: <AddStripePayment />,
	EDIT_ADDRESS : <EditMapAddress />,
};
