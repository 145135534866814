export function xorEncrypt(data: string, key: any): string {
    let encryptedData = '';
    for (let i = 0; i < data.length; i++) {
      encryptedData += String.fromCharCode(data?.charCodeAt(i) ^ key?.charCodeAt(i % key.length));
    }
    return encryptedData;
  }

  export function xorDecrypt(encryptedData: string, key: any): string {
    return xorEncrypt(encryptedData, key); 
  }